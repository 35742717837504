import React, { useEffect, useMemo } from "react"
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined"
import { ActivityCardContainer, HeartIcon, HeartIconSelected } from "./style" // Assurez-vous d'importer les composants stylisés
import { Activity } from "backend/api/activities"
import { Title, Text, Button, Column, Link } from "styled_components"
import { Step } from "widget"
import dayjs from "dayjs"
import { formatDate, isArrayEmpty } from "helpers/helpers"
import { useTranslation } from "react-i18next"
import CancellationPolicyModal from "./CancellationPolicyModal"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import { Navigation } from "swiper/modules"
import "./ActivityCard.css"

interface BookingDate {
  from: string
  to: string
}

interface Props {
  activity: Activity
  handleSelect?: (activity: Activity, isSelected: boolean) => void
  isSelected?: boolean
  handleChangeStep?: (step: Step) => void
  bookingDate?: BookingDate
  isCarouselActive?: boolean
}

const ActivityCard = ({
  activity,
  handleSelect,
  isSelected,
  handleChangeStep,
  bookingDate,
  isCarouselActive,
}: Props) => {
  const { t } = useTranslation("activityList")
  const [isCancellationPolicyModalOpen, setIsCancellationPolicyModalOpen] = React.useState(false)
  const colors = useSelector((state: RootState) => state.widget?.style?.colors)

  const activityPictures = useMemo(() => {
    const { images } = activity.informations
    if (isArrayEmpty(images)) return []
    else return images.map((image) => image.url)
  }, [activity])

  const isActivityFinish =
    activity.hours.dates?.to && new Date(activity.hours.dates?.to || "") < new Date()

  const isResumeDisplay = bookingDate !== undefined

  const isSelectableActivityCard =
    isSelected !== undefined &&
    handleSelect !== undefined &&
    handleChangeStep !== undefined &&
    !isActivityFinish

  const isTemporaryActivity = activity.hours.dates?.from && activity.hours.dates?.to

  if (isActivityFinish) {
    return <></>
  }

  useEffect(() => {
    if (isCarouselActive) {
      const style = document.createElement("style")
      const swiperBtnColor = colors?.actionBtnTextColor ?? "#fff"
      const swiperBtnBackground = colors?.actionBtnBackgroundColor ?? "#000"
      style.innerHTML = `
        .swiper-button-next, .swiper-button-prev {
          color: ${swiperBtnColor} !important;
          background-color: ${swiperBtnBackground} !important;
        }
      `

      document.head.appendChild(style)
    }
  }, [isCarouselActive, colors])

  return (
    <ActivityCardContainer
      activitycardbackgroundcolor={colors?.activityCardBackgroundColor}
      activitycardtextcolor={colors?.activityCardTextColor}
      iscarouselactive={Boolean(isCarouselActive)}
    >
      {isCancellationPolicyModalOpen && (
        <CancellationPolicyModal
          isOpen={isCancellationPolicyModalOpen}
          handleClose={() => setIsCancellationPolicyModalOpen(false)}
          cancellationPolicy={activity.informations.cancelation}
        />
      )}
      <Column>
        {!isArrayEmpty(activityPictures) && (
          <>
            {activityPictures.length > 1 && isCarouselActive ? (
              <Swiper
                onSwiper={(swiper: any) => {
                  swiper.navigation.nextEl.classList.add("swiper-button-next")
                  swiper.navigation.prevEl.classList.add("swiper-button-prev")
                }}
                navigation={true}
                modules={[Navigation]}
                style={{ width: "500px" }}
              >
                {activityPictures.map((pic, i) => (
                  <SwiperSlide key={i}>
                    <img
                      style={{
                        color: colors?.actionBtnTextColor,
                        width: "100%",
                      }}
                      src={pic}
                      alt={`activity picture ${i + 1}`}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <img
                className="activity-pic"
                style={{
                  color: colors?.actionBtnTextColor,
                  height: isCarouselActive ? "23rem" : "13rem",
                  width: isCarouselActive ? "70%" : "22rem",
                  ...(isCarouselActive ? {} : { margin: "auto" }),
                }}
                src={activityPictures[0]}
                alt="activity-pic"
              />
            )}
          </>
        )}
      </Column>
      <div className="activity-content">
        <div className="activity-infos">
          <div>
            <Title margin="0 0 20px 0" bold size={"18px"}>
              {activity.informations.name}
            </Title>
            <Text margin="0 0 15px 0">
              <FmdGoodOutlinedIcon
                style={{ color: "black", fontSize: "16px", marginRight: "4px" }}
              />{" "}
              {activity.informations.address}
            </Text>
            {!isResumeDisplay ? (
              <>
                {isTemporaryActivity ? (
                  <Text grey margin="0 0 20px 0">
                    {t("from")} {formatDate(activity.hours.dates?.from || "")} {t("to")}{" "}
                    {formatDate(activity.hours.dates?.to || "")}
                  </Text>
                ) : (
                  <Text grey margin="0 0 20px 0">
                    {t("noTemporaryActivity")}
                  </Text>
                )}
              </>
            ) : (
              <Column space margin="0 0 20px 0" width="100%" start>
                <Title style={{ whiteSpace: "nowrap" }} bold>
                  Date :
                  <Title margin="0 0 0 5px">{dayjs(bookingDate.from).format("DD/MM/YYYY")}</Title>
                </Title>
                <Title style={{ whiteSpace: "nowrap" }} margin="5px 0 0 0" bold>
                  {t("hour")} :{" "}
                  <Title margin="0 0 0 5px">{dayjs(bookingDate.from).format("HH[h]mm")}</Title>
                </Title>
                <Text style={{ whiteSpace: "nowrap" }} grey margin="5px 0 0 0" bold>
                  {t("duration")} :{" "}
                  {`${dayjs(bookingDate.to).diff(dayjs(bookingDate.from), "hour").toString().padStart(2, "0")}h` +
                    `${(dayjs(bookingDate.to).diff(dayjs(bookingDate.from), "minute") % 60).toString().padStart(2, "0")}`}
                </Text>
              </Column>
            )}
          </div>
          {isSelectableActivityCard && (
            <div className="activity-card-button-container">
              <Button
                secondary={!isSelected}
                onClick={() => {
                  handleSelect(activity, isSelected)
                }}
                style={{
                  color: colors?.actionBtnTextColor,
                  backgroundColor: colors?.actionBtnBackgroundColor,
                  width: "min-content",
                }}
              >
                {isSelected ? (
                  <HeartIconSelected
                    className="activity-action-icon"
                    style={{
                      fill: colors?.actionBtnTextColor,
                      backgroundColor: colors?.actionBtnBackgroundColor,
                    }}
                  />
                ) : (
                  <HeartIcon
                    className="activity-action-icon"
                    style={{
                      fill: colors?.actionBtnTextColor,
                      backgroundColor: colors?.actionBtnBackgroundColor,
                    }}
                  />
                )}
                {isSelected ? t("selected") : t("book")}
              </Button>
              {isSelected && (
                <Link
                  style={{
                    color: colors?.actionBtnTextColor,
                    backgroundColor: colors?.actionBtnBackgroundColor,
                    width: "min-content",
                  }}
                  onClick={() => handleChangeStep("dateTicketPicker")}
                  margin="10px 0 0 0"
                >
                  {t("validateReservation")}
                </Link>
              )}
            </div>
          )}
        </div>

        <div>
          <pre style={{ whiteSpace: "pre-wrap" }} className="activity-description">
            {activity.informations.description}
          </pre>
          {isResumeDisplay && activity.informations.cancelation && (
            <Text onClick={() => setIsCancellationPolicyModalOpen(true)} underline pointer>
              {t("cancellationPolicy")}
            </Text>
          )}
        </div>
      </div>
    </ActivityCardContainer>
  )
}

export default ActivityCard
